import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";
import CampaignSummaryApi from "../../api/campaign-msvc/campaign-summary.api";
import FacebookIcon from "../../components/icon/facebook-icon";
import InstagramIcon from "../../components/icon/instagram-icon";
import TargetIcon from "../../components/icon/target-icon";
import TikTokIcon from "../../components/icon/tiktok-icon";
import EmpLink from "../../components/shared/emp-link/emp-link";
import EmpPill from "../../components/shared/EmpPill/EmpPill";
import EmpTable, {
  EmpTableContentSpec,
  EmpTableSeperator,
} from "../../components/shared/EmpTable/EmpTable";
import { TASK_STATUS, TASK_TYPE } from "../../constants/app.constants";
import { AppContext } from "../../context/app.context";
import { ActiveTaskDto } from "../../model/campaign/active-task.dto";
import { Color } from "../../utilities/colors";
import EmpExceptionHandler from "../../utilities/errorUtils/empExceptionHandler";
import FormFieldUtils from "../../utilities/form-field.util";
import UserUtils from "../../utilities/user-utils";
import { PILL_COLORS } from "../../constants/pill-mappers.constants";
import XIcon from "../../components/icon/x-icon";
import { EmpCoinIcon } from "../../components/shared/emp-coin-icon/emp-coin-icon";

interface Props {
  onLoaded: () => void;
}
const AgencyActiveTasksTable = (props: Props) => {
  const { onLoaded } = props;
  const intl = useIntl();
  const navigate = useNavigate();
  const { user: userContext } = useContext(AppContext);
  const [activeTaskRecords, setActiveTaskRecords] = useState<
    (ActiveTaskDto | EmpTableSeperator)[]
  >([]);
  const [isLoading, setLoading] = useState(true);
  const tableContentSpec: EmpTableContentSpec<ActiveTaskDto>[] = useMemo(() => {
    return [
      {
        title: intl.formatMessage({
          id: "agencyHomePage_campaignTaskTableTaskHeader",
        }),
        dataIndex: "taskAndCampaign",
        render: (record) => {
          return (
            <div className="task-name-cell">
              <img
                className="campaign-img"
                alt={record.taskName}
                src={record.campaignImage}
              />
              <div>
                <EmpLink
                  text={record.taskName}
                  onSubmit={() => {
                    navigate(
                      `/agency/campaign-details/${record.campaignId}/task/${record.taskId}`
                    );
                  }}
                />
                <div className="task-platform-wrapper">
                  {record.taskPlatform === TASK_TYPE.FACEBOOK && (
                    <>
                      <FacebookIcon
                        size={10}
                        backgroundColor={Color.NEUTRAL[400]}
                      />
                      <span className="task-type-small">Facebook</span>
                    </>
                  )}
                  {record.taskPlatform === TASK_TYPE.INSTAGRAM && (
                    <>
                      <InstagramIcon
                        size={10}
                        top={0.5}
                        backgroundColor={Color.NEUTRAL[400]}
                      />
                      <span className="task-type-small">Instagram</span>
                    </>
                  )}
                  {record.taskPlatform === TASK_TYPE.TIKTOK && (
                    <>
                      <TikTokIcon
                        size={10}
                        backgroundColor={Color.NEUTRAL[400]}
                      />
                      <span className="task-type-small">TikTok</span>
                    </>
                  )}
                  {record.taskPlatform === TASK_TYPE.GENERAL && (
                    <>
                      <TargetIcon
                        size={10}
                        backgroundColor={Color.NEUTRAL[400]}
                      />
                      <span className="task-type-small">General Task </span>
                    </>
                  )}
                  {record.taskPlatform === TASK_TYPE.X && (
                    <>
                      <XIcon size={10} backgroundColor={Color.NEUTRAL[400]} />
                      <span className="task-type-small">
                        X (Formerly Twitter) Task{" "}
                      </span>
                    </>
                  )}
                </div>
              </div>
            </div>
          );
        },
      },
      {
        title: "Status",
        dataIndex: "status",
        render: (record) => {
          return (
            <>
              {record.taskStatus === TASK_STATUS.ONGOING && (
                <EmpPill text={"Ongoing"} {...PILL_COLORS.primary} />
              )}
              {record.taskStatus === TASK_STATUS.RECRUITING && (
                <EmpPill text={"Recruiting"} {...PILL_COLORS.amber} />
              )}
            </>
          );
        },
      },
      {
        title: "Amount Committed",
        dataIndex: "amountCommitted",
        render: (record) => {
          return (
            <div style={{ display: "flex", alignItems: "center", gap: 3 }}>
              <span className="raw-text">
                {FormFieldUtils.formatNumber(record.amountCommitted)}
              </span>
              <EmpCoinIcon
                iconValue={record.taskPaymentMode}
                mode="icon-only"
              />
            </div>
          );
        },
      },
      {
        title: "Participants",
        dataIndex: "participatingCreator",
        render: (record) => {
          return (
            <div className="creators-cell">
              {record.creators.length > 0 &&
                record.creators.map((elem) => {
                  return (
                    <div key={elem.id}>
                      {elem.imageType === "url" && (
                        <img
                          className="profile-img"
                          src={elem.imageResource}
                          alt={elem.fullName}
                        />
                      )}
                      {elem.imageType === "avatar" && (
                        <div
                          className="profile-img"
                          style={{ background: elem.imageResource }}
                        >
                          <span className="initials">{elem.initials}</span>
                        </div>
                      )}
                    </div>
                  );
                })}
              {record.creators.length === 0 && (
                <span className="raw-text">No Creators</span>
              )}
            </div>
          );
        },
      },
    ];
  }, [intl]);

  const fetchActiveTasks = useCallback(async () => {
    try {
      setLoading(true);
      const organisation = await UserUtils.fetchOrganisation(userContext);
      const resp = await CampaignSummaryApi.fetchAgencyActiveTasks(
        organisation.id
      );
      const records = resp.data;
      const recruitingTasks = records.filter(
        (elem) => elem.taskStatus === TASK_STATUS.RECRUITING
      );
      const ongoingTasks = records.filter(
        (elem) => elem.taskStatus === TASK_STATUS.ONGOING
      );

      const tableRecords: (ActiveTaskDto | EmpTableSeperator)[] = [];
      if (recruitingTasks.length > 0) {
        tableRecords.push({
          type: "emp-table-separator",
          content: "Showing recruiting tasks",
        });
        tableRecords.push(...recruitingTasks);
      }
      if (ongoingTasks.length > 0) {
        tableRecords.push({
          type: "emp-table-separator",
          content: "Showing ongoing tasks",
        });
        tableRecords.push(...ongoingTasks);
      }
      onLoaded();
      setActiveTaskRecords(tableRecords);
    } catch (err) {
      EmpExceptionHandler.handleHttpRequestError(
        err,
        "Unable to fetch active tasks"
      );
    } finally {
      setLoading(false);
    }
  }, [userContext]);

  useEffect(() => {
    fetchActiveTasks();
  }, [fetchActiveTasks]);

  return (
    <div className="emp-card no-padding mt-3 active-tasks-card">
      <div className="header-section">
        <h2 className="card-header-lbl">
          <FormattedMessage id="agencyHomePage_campaignTaskCardHeader" />
        </h2>
        <p className="emp-paragraph mt-2">
          <FormattedMessage id="agencyHomePage_campaignTaskCardDesc" />
        </p>
      </div>
      <EmpTable
        paddingSize="sm"
        loading={isLoading || activeTaskRecords.length === 0}
        showEmptyState={false}
        shimmerLoading={{
          width: [120, 60, 200, 100],
          overlayDesign: (
            <div className="table-overlay-wrapper">
              <img
                alt="No tasks"
                src="https://creatorbuzz-public-bucket.s3.ap-southeast-1.amazonaws.com/assets/gray-loudspeaker.png"
              />
              <span className="header">No Participating Campaigns</span>
              <span className="description">
                You're not involved in any campaigns yet
              </span>
            </div>
          ),
        }}
        contentColumns={tableContentSpec}
        data={activeTaskRecords}
        rowKey={"id"}
      />
    </div>
  );
};

export default AgencyActiveTasksTable;
