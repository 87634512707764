import { ResourcesConfig } from "aws-amplify";

export type EmpEnvironment =
  | "staging"
  | "staging-dev"
  | "demo-dev"
  | "production"
  | "development";
export type AirwallexEnvironment = "demo" | "prod";

export interface EmpAppConfig {
  userMsvc: string;
  adminMsvc: string;
  referralAndSupportMsvc: string;
  paymentMsvc: string;
  aws: ResourcesConfig;
  campaignMsvc: string;
  notificationMsvc: string;
  smmMsvc: string;
  appUrl: string;
  thirdWebClientId?: string;
  sellerAddress?: string;
}

export const getEnv = (): EmpEnvironment => {
  return process.env["REACT_APP_ENVIRONMENT"] as EmpEnvironment;
};

export const getEnvVar = () => {
  const env = process.env["REACT_APP_ENVIRONMENT"] as EmpEnvironment;
  return ENV_CONSTANTS[env];
};

export const getAirwallexEnv = (): AirwallexEnvironment => {
  const empEnv = process.env["REACT_APP_ENVIRONMENT"] as EmpEnvironment;
  if (
    ["development", "demo", "staging-dev", "staging", "demo-dev"].includes(
      empEnv
    )
  )
    return "demo";
  else if (empEnv === "production") return "prod";
  else throw new Error("Unknown React App Environment");
};

export const ENV_CONSTANTS: { [key: string]: EmpAppConfig } = {
  development: {
    userMsvc: "http://localhost:8081/user",
    adminMsvc: "http://localhost:8080/admin",
    referralAndSupportMsvc: "http://localhost:8083/rns",
    paymentMsvc: "http://localhost:8084/payment",
    campaignMsvc: "http://localhost:8085/campaign",
    notificationMsvc: "http://localhost:8086/notification",
    smmMsvc: "http://localhost:8087/smm",
    thirdWebClientId: "e40b16d779550d07c65ff8fceece7c2c",
    sellerAddress: "0x317D78D5Ade1FB5e6E04290759A4AB5d1da4a271",
    aws: {
      Auth: {
        Cognito: {
          userPoolClientId: "7is8a7p16l1ck5an9b1l63svnh",
          userPoolId: "ap-southeast-1_qPllpieru",
          loginWith: {
            oauth: {
              domain: "auth-dev.creatorbuzz.io",
              scopes: ["phone", "email", "profile", "openid"],
              redirectSignIn: ["https://localhost:3100/oauth-redirect"],
              redirectSignOut: ["https://localhost:3100/sign-in"],
              responseType: "code",
            },
          },
        },
      },
    },
    appUrl: "https://localhost:3100",
  },
  "development-emp": {
    userMsvc: "http://localhost:8081/user",
    adminMsvc: "http://localhost:8080/admin",
    referralAndSupportMsvc: "http://localhost:8083/rns",
    paymentMsvc: "http://localhost:8084/payment",
    campaignMsvc: "http://localhost:8085/campaign",
    notificationMsvc: "http://localhost:8086/notification",
    smmMsvc: "http://localhost:8087/smm",
    thirdWebClientId: "e40b16d779550d07c65ff8fceece7c2c",
    sellerAddress: "0x317D78D5Ade1FB5e6E04290759A4AB5d1da4a271",
    aws: {
      Auth: {
        Cognito: {
          userPoolClientId: "4mcnvmhaakgi9ivp84hhgjmtnp",
          userPoolId: "ap-southeast-1_QHW3q7PT5",
          loginWith: {
            oauth: {
              domain: "auth.emplifive.com",
              scopes: ["phone", "email", "profile", "openid"],
              redirectSignIn: ["https://localhost:3100/oauth-redirect"],
              redirectSignOut: ["https://localhost:3100/sign-in"],
              responseType: "code",
            },
          },
        },
      },
    },
    appUrl: "https://localhost:3100",
  },
  staging: {
    adminMsvc: "https://api-staging.creatorfi.io/admin",
    userMsvc: "https://api-staging.creatorfi.io/user",
    referralAndSupportMsvc: "https://api-staging.creatorfi.io/rns",
    paymentMsvc: "not-applicable",
    campaignMsvc: "https://api-staging.creatorfi.io/campaign",
    notificationMsvc: "https://api-staging.creatorfi.io/notification",
    smmMsvc: "not-applicable",
    thirdWebClientId: "e40b16d779550d07c65ff8fceece7c2c",
    sellerAddress: "0x317D78D5Ade1FB5e6E04290759A4AB5d1da4a271",
    aws: {
      Auth: {
        Cognito: {
          userPoolClientId: "6bm0oeu6lc1oaoj6bdv5trjir4",
          userPoolId: "ap-southeast-1_jTp2uUIvw",
          loginWith: {
            oauth: {
              domain: "auth-staging.creatorfi.io",
              scopes: ["phone", "email", "profile", "openid"],
              redirectSignIn: ["https://staging.creatorfi.io/oauth-redirect"],
              redirectSignOut: ["https://staging.creatorfi.io/sign-in"],
              responseType: "code",
            },
          },
        },
      },
    },
    appUrl: "https://staging.creatorfi.io",
  },
  "staging-dev": {
    userMsvc: "http://localhost:8081/user",
    adminMsvc: "http://localhost:8080/admin",
    referralAndSupportMsvc: "http://localhost:8083/rns",
    paymentMsvc: "not-applicable",
    campaignMsvc: "http://localhost:8085/campaign",
    notificationMsvc: "http://localhost:8086/notification",
    smmMsvc: "not-applicable",
    thirdWebClientId: "e40b16d779550d07c65ff8fceece7c2c",
    sellerAddress: "0x317D78D5Ade1FB5e6E04290759A4AB5d1da4a271",
    aws: {
      Auth: {
        Cognito: {
          userPoolClientId: "6bm0oeu6lc1oaoj6bdv5trjir4",
          userPoolId: "ap-southeast-1_jTp2uUIvw",
          loginWith: {
            oauth: {
              domain: "auth-staging.creatorfi.io",
              scopes: ["phone", "email", "profile", "openid"],
              redirectSignIn: ["https://localhost:3100/oauth-redirect"],
              redirectSignOut: ["https://localhost:3100/sign-in"],
              responseType: "code",
            },
          },
        },
      },
    },
    appUrl: "https://localhost:3100",
  },
  "production-dev": {
    userMsvc: "http://localhost:8081/user",
    adminMsvc: "http://localhost:8080/admin",
    referralAndSupportMsvc: "http://localhost:8083/rns",
    paymentMsvc: "http://localhost:8084/payment",
    campaignMsvc: "http://localhost:8085/campaign",
    notificationMsvc: "http://localhost:8086/notification",
    smmMsvc: "http://localhost:8087/smm",

    aws: {
      Auth: {
        Cognito: {
          userPoolClientId: "1b7sve14ufpihommi3vqgh52h4",
          userPoolId: "ap-southeast-1_YX8qh1Lku",
          loginWith: {
            oauth: {
              domain: "auth-prod.emplifive.com",
              scopes: ["phone", "email", "profile", "openid"],
              redirectSignIn: ["https://localhost:3100/oauth-redirect"],
              redirectSignOut: ["https://localhost:3100/sign-in"],
              responseType: "code",
            },
          },
        },
      },
    },
    appUrl: "https://platform.emplifive.com",
  },
  production: {
    adminMsvc: "https://api.creatorfi.io/admin",
    userMsvc: "https://api.creatorfi.io/user",
    referralAndSupportMsvc: "https://api.creatorfi.io/rns",
    paymentMsvc: "https://api.creatorfi.io/payment",
    campaignMsvc: "https://api.creatorfi.io/campaign",
    notificationMsvc: "https://api.creatorfi.io/notification",
    smmMsvc: "https://api.creatorfi.io/smm",
    thirdWebClientId: "7bdd687ebb3fb2857809361e414ec607",
    sellerAddress: "0x7436Af76dfC412a8De32C3488f9C4F6D2BDc59c5",
    aws: {
      Auth: {
        Cognito: {
          userPoolClientId: "27upj6jba47qk9kc42jii9icbf",
          userPoolId: "ap-southeast-1_CVvChLxW7",
          loginWith: {
            oauth: {
              domain: "auth.creatorfi.io",
              scopes: ["phone", "email", "profile", "openid"],
              redirectSignIn: ["https://app.creatorfi.io/oauth-redirect"],
              redirectSignOut: ["https://app.creatorfi.io/sign-in"],
              // redirectSignIn: ["https://localhost:3100/oauth-redirect"],
              // redirectSignOut: ["https://localhost:3100/sign-in"],
              responseType: "code",
            },
          },
        },
      },
    },
    appUrl: "https://platform.emplifive.com",
  },
};
