class FormFieldUtils {
  static defaultIfEmpty(text: string, defaultText?: string): string {
    const DEFAULT = defaultText ?? "-";
    if (!text) return DEFAULT;
    if (text.length === 0) return DEFAULT;
    return text;
  }

  static defaultIfZero(number: number, defaultText?: string): string {
    const DEFAULT = defaultText ?? "-";
    if (number === 0) return DEFAULT;
    return number.toString();
  }

  static toCompact(amount: number) {
    return `${new Intl.NumberFormat("en-US", {
      notation: "compact",
    }).format(amount)}${amount > 1000 ? "+" : ""}`;
  }

  static roundToFiveSignificantDigits(num: number): number {
    if (num === 0) return 0;
    const digitsBeforeDecimal = Math.floor(Math.log10(Math.abs(num))) + 1;
    const factor = Math.pow(10, 5 - digitsBeforeDecimal);
    const roundedNum = Math.round(num * factor) / factor;
    return roundedNum;
  }

  static formatNumber(num: number): string {
    let formattedAmount: string;

    // Handle very small numbers (in scientific notation) by converting them to fixed decimal
    if (num === 0) {
      return "0.00";
    }
    if (Math.abs(num) < 1e-6) {
      formattedAmount = num.toFixed(8); // Increase the precision for very small numbers
    } else if (Math.abs(num) < 0.01) {
      formattedAmount = this.roundToTwoSignificantDigits(num).toString();
    } else {
      formattedAmount = num.toFixed(2); // Format larger numbers with 2 decimal places
    }

    // Split the amount into integer and fractional parts
    let [integerPart, fractionalPart] = formattedAmount.split(".");

    // Format the integer part with commas
    integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ",");

    // Reassemble the formatted integer and fractional parts
    if (fractionalPart !== undefined) {
      return `${integerPart}.${fractionalPart}`;
    } else {
      return integerPart;
    }
  }

  static roundToTwoSignificantDigits(num: number): number {
    if (num === 0) return 0;
    const digitsBeforeDecimal = Math.floor(Math.log10(Math.abs(num))) + 1;
    const factor = Math.pow(10, 2 - digitsBeforeDecimal);
    const roundedNum = Math.round(num * factor) / factor;
    return roundedNum;
  }

  static toCommify(
    amount: number,
    toFixed: boolean = false,
    decimalPlaces: number = 2
  ): string {
    let formattedAmount: string;

    if (toFixed) {
      formattedAmount = amount.toFixed(decimalPlaces);
    } else {
      formattedAmount = amount.toString();
    }

    // Split the amount into integer and fractional parts
    let [integerPart, fractionalPart] = formattedAmount.split(".");

    // Format the integer part with commas
    integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ",");

    // Reassemble the formatted integer and fractional parts
    if (fractionalPart !== undefined) {
      return `${integerPart}.${fractionalPart}`;
    } else {
      return integerPart;
    }
  }

  static toMoney(
    amount: number,
    hasCurrency?: boolean,
    currency?: string
  ): string {
    const DEFAULT_CURRENCY = currency ?? "USD";
    const HAS_CURRENCY = hasCurrency ?? false;
    const formattedNum = amount
      .toFixed(2)
      .replace(/\d(?=(?:\d{3})+\.)/g, "$&,");
    if (HAS_CURRENCY) {
      return `${DEFAULT_CURRENCY} ${formattedNum}`;
    }
    return `${formattedNum}`;
  }

  static toMoneyWithoutDecimals(
    amount: number,
    hasCurrency?: boolean,
    currency?: string
  ): string {
    const DEFAULT_CURRENCY = currency ?? "USD";
    const HAS_CURRENCY = hasCurrency ?? false;
    const formattedNum = amount
      .toString()
      .replace(/\d(?=(?:\d{3})+\.)/g, "$&,");
    if (HAS_CURRENCY) {
      return `${DEFAULT_CURRENCY} ${formattedNum}`;
    }
    return `${formattedNum}`;
  }
}

export default FormFieldUtils;
